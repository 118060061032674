@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppins";
  src: url("../public/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins-bold";
  src: url("../public/Poppins-SemiBold.ttf") format("truetype");
}

.fc-daygrid-day-frame .fc-scrollgrid-sync-inner {
  max-height: 5vh !important;
}

.fc-daygrid-day-bg {
  max-height: 5vh !important;
}

.fc-daygrid-day-events {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto !important;
}

/* .fc-event {
  font-family: "Avenir", "Helvetica", "Arial", "sans-serif" !important;
  font-size: 0.8em !important;
  background-color: rgba(253, 215, 0, 0.473) !important;
} */

.fc-h-event {
  padding: 0.2rem;
  border-radius: 8px !important;
  opacity: 0.85;
}

.fc-h-event .fc-event-title {
  font-family: "poppins";
  font-weight: 600;
  font-size: 0.92em;
  color: #2b313f;
  letter-spacing: 0.2px;
}
.fc-daygrid-day-number {
  font-family: "poppins";
  font-weight: 600;
  font-size: 0.9em;
  color: grey;
}

.fc-day-today {
  background-color: white !important;
}
.fc-day-today .fc-daygrid-day-number {
  font-family: "poppins-bold";
  padding: 0.2rem !important;
  margin: 0.4rem;
  border-radius: 9px;
  font-weight: 600;
  font-size: 0.9em;
  /* color: white; */
  /* background-color: #fbbf24; */
  border: 2px solid #fbbe2471;
  /* box-shadow: 0 0 2px #fbbe2463; */
}
.fc-scrollgrid-sync-inner {
  font-family: "poppins";
  color: grey;
  font-size: 0.9em;
  font-weight: 600;
  /* padding-top: 0.4rem;
  padding-bottom: 0.4rem; */
}
.fc-daygrid-day-number {
  padding: 0.8rem !important;
}
.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

.fc-day-past .fc-scrollgrid-sync-inner {
  background-color: #f2f7f77e !important;
  padding: -10px;
}
.fc-day .fc-scrollgrid-sync-inner:hover {
  background-color: #fffbeb54 !important;
  cursor: pointer;
}

.fc-toolbar-title {
  font-family: "poppins-bold";
  padding: 0.9rem !important;
  font-size: 1.1em !important;
  font-weight: 600 !important;
  color: #333333d8;
}
.fc .fc-toolbar {
  font-family: "poppins";
  background-color: #f8fafb49 !important;
  height: 60px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin: 0 !important;
}

/* .fc-col-header-cell {
  border-bottom: 1px solid #d0d3d4 !important;
} */

.fc-toolbar-chunk {
  margin-right: 1rem;
}
.fc-button-primary {
  border: 1px solid #d0d3d400 !important;
  color: black !important;
  background-color: white !important;
  /* padding: 7px 6px !important; */
  border-radius: 8px !important;
}

.fc-prev-button {
  margin-right: 0.4rem !important;
}
.fc .fc-button {
  padding: 0.3rem !important;
}

.fc-icon {
  color: grey !important;
  font-size: 1rem !important;
}

.fc .fc-button .fc-icon {
  vertical-align: baseline !important;
}

.fc .fc-button {
  max-height: 40px !important;
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: white;
}

body::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 100px;
}

/* .loginBackground {
  background: linear-gradient(-45deg, #fbbf24, #e73c7e, #fbbf24, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

.loginBackground {
  height: 100%;
  width: 100%;
  background-color: #fcc437b9;
  background-image: url("https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png"),
    url("https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png"),
    url("https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png");
  background-repeat: repeat-x;
  background-position: 0 40%, 0 100%, 0 80%, 0 100%, 0 40%;
  background-size: 0px, 0px, 500px 0px, 1000px, 400px 260px;
  animation: 40s para infinite;
}

@keyframes para {
  100% {
    background-position: -1000px 20%, -100px 95%, 500px 50%, 1000px 100%,
      400px 0;
  }
}

.fc-daygrid table {
  border-collapse: separate !important;
  border-color: white !important;
  border-radius: 11px !important;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.035));
}

.fc-daygrid table th:first-child {
  border-top-left-radius: 11px !important;
}
.fc-daygrid table th:last-child {
  border-top-right-radius: 11px !important;
}

.reactour__helper {
  font-family: "poppins";
  border-radius: 11px !important;
}

.small-title {
  font-family: "poppins-bold";
  font-size: 0.9rem;
}
